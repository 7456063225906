<template>
    <div class="details-wrapper">
        <global-page-back
            :detailPageTitle="detailPageTitle"
            @handlerGlobalType="handlerGlobalType"
        ></global-page-back>
        <div class="details-content">
            <div class="filter-box">
                <div>
                    <el-input
                        class="formItem"
                        v-model="findObj.filledName"
                        placeholder="请输入姓名"
                        clearable
                    ></el-input>
                    <el-cascader
                        v-model="findObj.organIdList"
                        class="formItem"
                        :options="zzOption"
                        placeholder="请选择组织架构"
                        :props="props"
                        collapse-tags
                        clearable
                    ></el-cascader>
                    <el-input
                        class="formItem"
                        v-model="findObj.lowScore"
                        placeholder="请输入最低得分"
                        clearable
                        @input="handleTypeScore($event, 'lowScore')"
                    ></el-input>
                    <el-input
                        class="formItem"
                        v-model="findObj.highScore"
                        placeholder="请输入最高得分"
                        clearable
                        @input="handleTypeScore($event, 'highScore')"
                    ></el-input>
                </div>
                <div class="fiBtn">
                    <el-button @click="resetBtn">重置</el-button>
                </div>
                <div class="fiBtn">
                    <el-button
                        type="primary"
                        @click="searchBtn"
                        >查询</el-button
                    >
                </div>
                <div class="btnLine"></div>
                <div class="fiBtn">
                    <el-button
                        :loading="exportLoading"
                        type="primary"
                        @click="exportList"
                        >导出全部数据</el-button
                    >
                </div>
            </div>
            <div class="table-box">
                <table-data
                    v-loading="loadingTable"
                    :to-top="false"
                    id="table"
                    ref="table"
                    :config="tableConfig"
                    :tableData="tableData"
                >
                    <template v-slot:formTitle="slotData">
                        <div
                            style="color: #3c7fff; cursor: pointer"
                            @click="openDetails(slotData.data)"
                        >
                            {{ slotData.data.name }}
                        </div>
                    </template>
                    <template v-slot:operation="slotData">
                        <el-button
                            type="text"
                            @click="showAnswer(slotData.data)"
                            >查看答案</el-button
                        >
                    </template>
                </table-data>
            </div>
            <div class="pagination-box">
                <Pagination
                    :page.sync="findObj.pageNum"
                    :limit.sync="findObj.pageRow"
                    :total="findObj.total"
                    @pagination="changeTableDataGet"
                />
            </div>
        </div>
        <dialog-wrapper
            class="details-dia"
            :dialog-obj="detailsDialog"
            @handleClose="handleDetailsClose"
        >
            <div class="import-wrap">
                <!-- <div>请设置新的表单填写的截止时间：</div> -->
                <div style="text-align: center">
                    <phone-details
                        :detailsObj="detailsObj"
                        :formTitle="formTitle"
                    ></phone-details>
                </div>
                <div class="close-box">
                    <i
                        class="el-icon-circle-close icon-color"
                        @click="handleDetailsClose"
                    ></i>
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>

  <script>
import TabbedPage from "@/components/scrollWrapper/Sub/TabbedPage/index.vue";
import {
    Loading,
    Error,
    Pagination,
    DialogWrapper,
    debounce,
} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import RecordsList from "@/libs/pageListOptimal.js";
import PhoneDetails from "@/components/scrollWrapper/InterconnectionInfoCollection/phoneDetailsResults.vue";
import {InterconnectionInfoCollectionStatostics} from "@/models/InterconnectionInfoCollectionStatostics.js";

import {mapState} from "vuex";
import {listToTree, downloadFile} from "@/libs/utils.js";
export default {
    name: "UserFillingStatus",
    props: ["id", "teacherId", "formType", "teacherName", "userPopulation"],
    components: {
        GlobalPageBack,
        TableData,
        PhoneDetails,
        Loading,
        Error,
        Pagination,
        DialogWrapper,
        TabbedPage,
    },
    data() {
        return {
            exportLoading: false,
            itemType: "其他",
            isShowFilter: true,
            isImage: false,
            props: {
                multiple: true,
                emitPath: false,
                checkStrictly: true,
                value: "id",
                label: "name",
            },
            zzOption: [], // 组织架构
            findObj: {
                teacherId: "",
                type: "0",
                schoolId: "",
                formId: "",
                pageNum: 1,
                pageRow: 20,
                total: 0,
                organIdList: [],
                userPopulation: "",
                filledName: "",
                lowScore: "",
                highScore: "",
            },
            detailPageTitle: "详情",
            loadingTable: false,
            tableData: [],
            detailsObj: [],
            formTitle: "",
            detailsDialog: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            tableConfig: {
                thead: [
                    {
                        label: "学生姓名",
                        // labelWidth: '180px',
                        prop: "filledName",
                        align: "center",
                    },
                    {
                        label: "所在班级",
                        // labelWidth: '180px',
                        prop: "organName",
                        align: "center",
                    },
                    {
                        label: "打分明细(分)",
                        // labelWidth: '180px',
                        prop: "answerInfo",
                        align: "center",
                    },
                    {
                        label: "最终结果(分)",
                        // labelWidth: '180px',
                        labelDescription:
                            "根据选项最高分转换为百分制后的多维度平均分",
                        prop: "score",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "180px",
                        slotName: "operation",
                        className: "text-spacing-reduction",
                        renderHeader: null,
                    },
                ],
                height: "",
                check: false,
                showIndex: true,
                showIndexWidth: "150px",
            },
        };
    },
    created() {
        this.findObj.schoolId = this.$store.state.schoolId;
        this.findObj.formId = this.id;
        this.findObj.teacherId = this.teacherId;
        this.findObj.userPopulation = this.userPopulation;
        // 教职工
        if (this.userPopulation == 1) {
            this.getBMData();
        }

        // 学生家长
        if (this.userPopulation == 2) {
            this.getClassList();
        }

        // 不限
        if (this.userPopulation == 3) {
            this.isShowFilter = false;
        }
    },

    mounted() {
        console.log("teachername", this.teacherName);
        this.detailPageTitle = this.teacherName + "的问卷详情";
        this.tableConfig.height =
            document.body.clientHeight -
            document.getElementById("table").offsetTop -
            146;
        window.onresize = debounce(() => {
            if (!document.getElementById("table")) return;
            const height =
                document.body.clientHeight -
                document.getElementById("table").offsetTop -
                146;
            this.tableConfig.height = height.toString();
        }, 200);
        this.getList();
    },
    methods: {
        /**
         * @Description: 拿取部门数据
         * @Author: 周浩
         * @Date: 2024-10-16 14:07:14
         */
        getBMData() {
            this._fet("/school/schoolOrgan/listByConditionNoAuth", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code == 200) {
                    let allList = res.data.data.list.filter(
                        (it) => it.organType == 1,
                    );

                    let options = listToTree(allList, {
                        parentKey: "parentOrg",
                    });
                    this.clearDataNull(options, "children");
                    this.zzOption = options;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        /**
         * @Description: 获取任教班级
         * @Author: 王梦瑶
         * @Date: 2024-08-16 10:42:18
         */
        getClassList() {
            let obj = {
                schoolId: this.$store.state.schoolId,
            };
            this._fet("/school/schoolOrgan/listByCondition", obj).then(
                (res) => {
                    if (res.data.code === "200") {
                        let arr = res.data.data.list.filter((item) => {
                            return item.organType != 1;
                        });
                        const treeData = listToTree(arr, {
                            parentKey: "parentOrg",
                        });

                        this.clearDataNull(treeData, "children");
                        this.zzOption = treeData;
                    } else {
                        this.$message.error("数据请求失败，请重试");
                    }
                },
            );
        },
        clearDataNull(data, key) {
            data.forEach((item) => {
                if (
                    item[key] == null ||
                    item[key] == undefined ||
                    item[key].length == 0
                ) {
                    delete item[key];
                } else {
                    this.clearDataNull(item[key], key);
                }
            });
        },
        handlerGlobalType() {
            this.$emit("changeComp", {
                comp: "EvaluationOfTeacherStatistics",
            });
        },
        searchBtn() {
            this.getList();
        },
        /**
         * @Description: 查看答卷详情列表
         * @Author: 王梦瑶
         * @Date: 2024-08-16 14:07:34
         */
        getList() {
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.getSchoolCollectDetailsList(
                this.findObj,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.tableData = res.data.data.list;
                        this.findObj.total = res.data.data.total;
                        //赋值--存储筛选项及滚动条高度
                        this.$nextTick(() => {
                            let recordsList = new RecordsList();
                            if (recordsList.get(this.$route.name)) {
                                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop =
                                    recordsList.get(
                                        this.$route.name,
                                    ).tableScrollTop;
                            }
                            //移除--存储筛选项及滚动条高度
                            recordsList.remove(this.$route.name);
                            this.loadingTable = false;
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                })
                .finally(() => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 导出全部
         * @Author: 周浩
         * @Date: 2024-08-22 11:43:59
         */
        exportList() {
            this.exportLoading = true;
            downloadFile(
                {
                    url: "/school/schoolCollectDetails/export",
                    method: "post",
                    form: {
                        schoolId: this.$store.state.schoolId,
                        formId: this.id,
                        teacherId: this.teacherId,
                        type: "0",
                        teacherName: this.teacherName,
                    },
                },
                () => {
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },
        /**
         * @Description: 修改页码重新请求
         * @Author: 王梦瑶
         * @Date: 2024-08-16 15:15:01
         */
        changeTableDataGet() {
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            });
            this.getList();
        },
        /**
         * @Description: 获取分数
         * @Author: 王梦瑶
         * @Date: 2024-08-22 14:33:38
         */
        handleTypeScore(e, label) {
            let maxvalue = e.replace(/[^\d]/g, ""); // 只能输入.和-和数字
            maxvalue = maxvalue.replace(/^\./g, ""); //第一个字符不能是.
            maxvalue = maxvalue.replace(/\.{2,}/g, "."); // 不能连续输入.
            maxvalue = maxvalue.replace(/(\.\d+)\./g, "$1"); // .后面不能再输入.
            maxvalue = maxvalue.replace(/(-)\./g, "$1"); // -后面不能输入.
            maxvalue = maxvalue.replace(/\-{2,}/g, "-"); // -只能保留一个
            maxvalue = maxvalue.replace(/(\d+|\.)-/g, "$1"); // 数字和.后面不能接-,不能出现类似11-, 12.-
            maxvalue = maxvalue.replace(/-(0){2,}/g, "$1"); // 不能出现-00,-001,-0001类似
            maxvalue = maxvalue.replace(/(-)0+(\d+)/g, "$1$2"); // 不能出现-01,-02类似
            maxvalue = maxvalue.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            maxvalue = maxvalue.replace(/(\d{10})\d*/, "$1"); // 最多保留10位整数
            maxvalue = maxvalue.replace(/(\.\d{1})\d*/, "$1"); // 最多保留1位小数
            console.log(maxvalue, "------");
            this.findObj[label] = maxvalue;
        },
        /**
         * @Description: 打开查看答案弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-08 15:58:51
         */
        showAnswer(row) {
            this.formTitle = row.filledName;
            // 获取答案
            // getAnwer
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.getAnwer(row.recordId)
                .then((res) => {
                    if (res.data.code == 200) {
                        let content = JSON.parse(
                            res.data.data.answerInfo,
                        ).content;
                        let info = JSON.parse(JSON.parse(content));
                        this.detailsObj = info;
                        this.detailsObj.forEach((item) => {
                            if (
                                item.compType == "multipleSelect" &&
                                item.value
                            ) {
                                item.defaultValue = item.value.map((i) => {
                                    return i.label;
                                });
                            }
                        });
                        this.detailsDialog.dialogVisible = true;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                    }
                })
                .finally(() => {});
        },
        /**
         * @Description: 关闭表单详情
         * @Author: 王梦瑶
         * @Date: 2024-08-08 14:57:04
         */
        handleDetailsClose() {
            this.detailsDialog.dialogVisible = false;
        },
        resetBtn() {
            this.findObj = this.$options.data().findObj;
            this.findObj.schoolId = this.$store.state.schoolId;
            this.findObj.formId = this.id;
            this.findObj.teacherId = this.teacherId;
            this.findObj.userPopulation = this.userPopulation;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
    // background-color: #EDF0F7;
    .details-content {
        height: calc(100vh - 180px);
        background-color: #fff;
        margin-top: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .filter-box {
            flex-shrink: 0;
            display: flex;
            text-align: center;
            align-items: center;
            background-color: #fff;
            overflow: hidden;
            padding: 10px 0px;

            .formItem {
                margin: 0 5px;
                width: 140px;
            }
            .formItem2 {
                margin: 5px;
                width: 280px;
            }

            .fiBtn {
                margin: 0 5px;
            }
            .btnLine{
                width: 1px;
                height: 25px;
                border-right: 1px dashed #d4d6d9;
                margin: 0 10px;

            }
        }
        .table-box {
            flex: 1;
        }
        .pagination-box {
            flex-shrink: 0;
            padding-bottom: 10px;
        }
    }
}
.invalid-btn {
    color: #e63a2e !important;
}

.close-box {
    text-align: center;
    font-size: 40px;
}

.icon-color {
    color: #fff;
    cursor: pointer;
}
::v-deep {
    .el-input__inner {
        background: #f5f5f5;
        border: transparent 1px solid;
    }
    .el-range-editor .el-range-input {
        background: #f5f5f5;
    }
    .el-cascader__search-input {
        background: #f5f5f5;
    }
    .el-select .el-input .el-select__caret {
        color: #999999;
    }
    .el-input-group__append {
        color: #999999;
        background: #f5f5f5;
        border: none;
    }
    .el-select-dropdown__list {
        border: 1px solid #d4d6d9;
    }
    .el-input__inner:hover {
        border: #d4d6d9 1px solid;
    }
    .el-input__inner:focus {
        border: #3c7fff 1px solid;
        background-color: #fff;
    }
    .el-cascader__tags {
        & > span:nth-of-type(1) {
            flex: 1 !important;
            overflow: hidden;
        }
        & > span:nth-of-type(2) {
            flex-shrink: 0 !important;
            overflow: hidden;
        }
    }
}
</style>
<style lang="scss">
.details-dia {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog .el-dialog__body {
        margin-top: 0px;
        padding: 0px;
    }

    .el-dialog__header {
        display: none !important;
    }
}
</style>
